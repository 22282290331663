import React, { useEffect } from "react";
import supporters from "../../Data/supporters.json";
import { Card } from "antd";
import "./supporters.scss";
import MetroLogo from "../../Assets/images/MetroMVMNT_Logo.png";

type SupportersProps = {
  updateSidebar: (e: string) => void;
  isMobile: boolean;
};

type SupporterType = {
  name: string;
  website: string;
  image?: boolean;
};

export default function Supporters(props: SupportersProps) {
  const { updateSidebar, isMobile } = props;

  useEffect(() => {
    updateSidebar("Supporters");
  }, [updateSidebar]);

  const styles = isMobile ? {} : { style: { display: "flex" } };

  const cardSupporters = (supporters: SupporterType[], category: string) => {
    let headColor = "";
    let bodyColor = "";
    if (category === "Gold") {
      headColor = "#b4975e";
      bodyColor = "#d5c4a2";
    }
    if (category === "Silver") {
      headColor = "#a7a9ac";
      bodyColor = "#d1d2d4";
    }
    if (category === "Bronze") {
      headColor = "#a27a5d";
      bodyColor = "#c2a794";
    }
    return (
      <Card
        title={category}
        headStyle={{ backgroundColor: headColor }}
        bodyStyle={{ backgroundColor: bodyColor }}
      >
        {supporters.map((supporter) => {
          return (
            <>
              {supporter.image ? (
                <a
                  key={supporter.name}
                  href={supporter.website}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "black" }}
                >
                  <img
                    style={{
                      maxWidth: "150px",
                      maxHeight: "150px",
                    }}
                    src={MetroLogo}
                    alt="metro-movement-logo"
                  />
                </a>
              ) : (
                <a
                  key={supporter.name}
                  href={supporter.website}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "black" }}
                >
                  <p>{supporter.name}</p>
                </a>
              )}
            </>
          );
        })}
      </Card>
    );
  };

  return (
    <div {...styles}>
      <div className="supporter-category gold">
        {cardSupporters(supporters.gold, "Gold")}
      </div>
      <div className="supporter-category silver">
        {cardSupporters(supporters.silver, "Silver")}
      </div>
      <div className="supporter-category bronze">
        {cardSupporters(supporters.bronze, "Bronze")}
      </div>
    </div>
  );
}
