import React, { useEffect } from "react";
import { Row, Col, Card } from "antd";
import RunablazeHistory from "../../Data/history/Blazing-history.pdf";
import IowaRecords from "../../Data/history/Iowa-Records.pdf";
import IowaStories from "../../Data/history/Iowa-Records-the-story.pdf";
import IowaMarathoners from "../../Data/history/Iowa-best-Marathoners.pdf";
import IowaYearlyRaceHistory from "../../Data/history/Iowa-yearly-race-history.pdf";
import IowaYearlyRaceHistory2024 from "../../Data/history/Iowa-yearly-race-history-2024.pdf";
import IowaBig4Summer from "../../Data/history/Iowa-Summer-Races.pdf";
import IowaOldestRaces from "../../Data/history/Iowa-oldest-races.pdf";
import Bix7 from "../../Data/history/BIX-7.pdf";
import CapPursuit from "../../Data/history/Cap-Pursuit.pdf";
import DamToDamHistory from "../../Data/history/Dam-history.pdf";
import DamToDamRecords from "../../Data/history/Dam-records.pdf";
import DsmMarathon from "../../Data/history/DSM-Marathon.pdf";
import DrakeRoadHistory from "../../Data/history/Drake-road-history.pdf";
import DrakeRoad10k from "../../Data/history/Drake-road-10k.pdf";
import DrakeRoadMarathon from "../../Data/history/Drake-road-marathon.pdf";
import FifthSeason8k from "../../Data/history/Fifth-season-8k.pdf";
import LhfResults from "../../Data/history/LHF-results.pdf";
import Marshalltown10k from "../../Data/history/Marshalltown-10k.pdf";
import MidnightMadness from "../../Data/history/Midnight-madness.pdf";
import ParkToPark from "../../Data/history/Park-to-park.pdf";
import SturgisFalls from "../../Data/history/Sturgis-falls.pdf";
import OtherIowaRaces from "../../Data/history/other-RACES.pdf";
import QcMarathon from "../../Data/history/QC-Marathon.pdf";
import HeartlandClassic from "../../Data/history/Heartland-Classic-XC.pdf";
import BobcatInvite from "../../Data/history/Bobcat-Invite-XC.pdf";

type HistoryProps = {
  updateSidebar: (e: string) => void;
};

const IowaHistory = [
  {
    name: "Runablaze Iowa History",
    link: RunablazeHistory,
  },
  {
    name: "Iowa All-Time Road Race Record Lists",
    link: IowaRecords,
  },
  {
    name: "Iowa All-Time Road Race Record Stories",
    link: IowaStories,
  },
  {
    name: "Iowa's Best Marathoners",
    link: IowaMarathoners,
  },
  {
    name: "Iowa Yearly Race History, 1970s to 2023",
    link: IowaYearlyRaceHistory,
  },
  {
    name: "Iowa Race Highlights, 2024",
    link: IowaYearlyRaceHistory2024,
  },
  {
    name: "Iowa Summer Big 4",
    link: IowaBig4Summer,
  },
  {
    name: "Iowa's Oldest Races",
    link: IowaOldestRaces,
  },
];
const IowaRaceHistory = [
  {
    name: "QC Times Bix 7 History and Records",
    link: Bix7,
  },
  {
    name: "Capital Pursuit 10M History and Records",
    link: CapPursuit,
  },
  {
    name: "Dam to Dam History",
    link: DamToDamHistory,
  },
  {
    name: "Dam to Dam Records",
    link: DamToDamRecords,
  },
  {
    name: "Des Moines Marathon",
    link: DsmMarathon,
  },
  {
    name: "Drake Relays Road Race History",
    link: DrakeRoadHistory,
  },
  {
    name: "Drake Relays Road Race 10km",
    link: DrakeRoad10k,
  },
  {
    name: "Drake Relays Road Race Marathon",
    link: DrakeRoadMarathon,
  },
  {
    name: "Fifth Season 8k Records and History",
    link: FifthSeason8k,
  },
  {
    name: "Living History Farms Past Results",
    link: LhfResults,
  },
  {
    name: "Marshalltown 10km Records",
    link: Marshalltown10k,
  },
  {
    name: "Midnight Madness History and Records",
    link: MidnightMadness,
  },
  {
    name: "Park to Park Half Marathon Records",
    link: ParkToPark,
  },
  {
    name: "Sturgis Falls Half Marathon Records",
    link: SturgisFalls,
  },
  {
    name: "Quad Cities Marathon Records",
    link: QcMarathon,
  },
  {
    name: "Other Iowa Race Highlights",
    link: OtherIowaRaces,
  },
];
const XcHistory = [
  {
    name: "Heartland Classic XC Meet Records",
    link: HeartlandClassic,
  },
  {
    name: "Marshalltown CC Bobcat Course Records",
    link: BobcatInvite,
  },
];

type HistoryItem = {
  name: string;
  link: string;
};

export default function History(props: HistoryProps) {
  const { updateSidebar } = props;

  useEffect(() => {
    updateSidebar("Iowa Running History");
  }, [updateSidebar]);

  const makeHistoryLink = (history: HistoryItem) => {
    return (
      <Row key={history.name} style={{ paddingBottom: 10 }}>
        <a href={history.link} rel="noopener noreferrer" target="_blank">
          {history.name}
        </a>
      </Row>
    );
  };

  return (
    <div>
      <Row style={{ paddingTop: 10 }}>
        <Col span={24} md={12} style={{ padding: 5 }}>
          <Card title={<b>History of Iowa Races</b>} style={{ height: "100%" }}>
            {IowaRaceHistory.map((history) => {
              return makeHistoryLink(history);
            })}
          </Card>
        </Col>
        <Col span={24} md={12} style={{ padding: 5 }}>
          <Card
            title={<b>History of Iowa Athletes</b>}
            style={{ height: "100%" }}
          >
            {IowaHistory.map((history) => {
              return makeHistoryLink(history);
            })}
            <br />
            <b>High School Cross Country</b>
            <br />
            {XcHistory.map((history) => {
              return makeHistoryLink(history);
            })}
          </Card>
        </Col>
      </Row>
      <Col span={24} style={{ padding: 5 }}>
        <Card style={{ padding: 5 }}>
          <h4>History documents maintained by Cal Murdock</h4>
          Questions or comments about Iowa Running History? Email
          calmurdock@earthlink.net
        </Card>
      </Col>
    </div>
  );
}
